body {
  font-family: "Roboto", sans-serif;
  color: #494949;
  background-color: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}