button {
  cursor: pointer;
  &.input-button {
    &:hover {
      border-color: $light-accent;
      background-color: $light-accent;
    }
    &:active {
      box-shadow: 0px 0px 0px 1.5px $accent inset;
    }
  }
  &.three-d {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1em 1em;
    background: $accent;
    border: 2px solid $accent-border;
    border-radius: 2em;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      background 150ms cubic-bezier(0, 0, 0.58, 1);
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $light-accent;
      border-radius: inherit;
      transform: translate3d(0, 0.5em, -1em);
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
        box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:not(:focus):hover {
      background: $light-accent;
    }

    &:active {
      background: $accent;
      transform: translate(0em, 0.5em);
      &::before {
        transform: translate3d(0, 0, -0.5em);
      }
    }
  }

  &.accent-button {
    align-items: center;
    background-color: $light-accent;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: $shade1;
    cursor: pointer;
    display: inline-flex;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
      box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
      color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;

    &:hover,
    &:focus {
      background-color: $accent;
      color: $shade1;
    }
    &:active {
      background: $accent;
      color: $shade1;
    }
    &:disabled {
      cursor: not-allowed;
      background: #00000010;
      color: #00000030;
    }
  }
  &.navbar-button {
    color: $shade1;
    background: none;
    text-decoration: none;
    padding: 5px 15px;
    font-family: "Poppins", sans-serif;
    font-size: 60%;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    &.inactive {
      cursor: auto;
      color: rgba($shade1, 0.4);
      &:hover {
        background: none;
      }
    }

    &:hover:not(.deselected):not(.inactive) {
      border-radius: 2em;
      background: $transparent-primary;
    }

    &:not(.inactive) {
      &:focus {
        border-color: $primary;
        background: $transparent-primary;
      }
    }
  }
  &.navbar-dropdown-button {
    color: $shade1;
    background: $accent;
    text-decoration: none;
    padding: 5px 15px;
    font-family: "Poppins", sans-serif;
    font-size: 60%;

    &:hover {
      border-radius: 2em;
      background: $light-accent;
    }
  }

  &.small-button {
    color: $shade1;
    background: $accent;
    text-decoration: none;
    padding: 5px 10px;
    font-family: "Poppins", sans-serif;
    font-size: 40%;

    &:hover {
      border-radius: 2em;
      background: $transparent-primary;
    }
  }
}
