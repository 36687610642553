$primary: #6fc6aa;
$light-primary: #78ceb2;
$transparent-primary: #6fc6aa55;
$accent: #f6c741;
$light-accent: #f9dd8a;
$accent-border: #f4b910;
$shade1: #494949;
$shade2: #e0e0e0;
$shade3: #f2f9f7;
$danger: #ff6961;
$transparent-danger: #ff696148;
