$loader-size: 1.2em;
$loader-margin: 0.5px;
$check-height: $loader-size/2.2;
$check-width: $check-height/2.5;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 4px;
.circle-loader {
  margin-bottom: $loader-size/2;
  border: 4px solid rgba(0, 0, 0, 0);
  border-left-color: $shade3;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
  margin-left: $loader-margin;
  margin-top: auto;
  margin-bottom: auto;
  &:focus-visible {
    outline: unset;
  }
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border: none;
}

.checkmark {
  display: none;

  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $shade3;
    border-top: $check-thickness solid $shade3;
    content: "";
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
}

#load-checkmark-space {
  margin-left: $loader-margin;
  width: $loader-size;
  display: block;
  z-index: 10000;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
