/* Style the top navigation bar */
$navbar-dropdown-elements: 6;

#navbar {
  /* position the heading */
  position: absolute;
  /* make sure that the heading is drawn on top */
  z-index: 1;
  /* background-color: #6fc6aa55; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  img {
    display: block;
    margin: 16px 16px;
    height: 60px;
    width: 60px;

    &:hover {
      cursor: pointer;
    }
  }
  .navbar-dropdown {
    /* position the heading */
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 80px;
    @keyframes load {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    div {
      opacity: 0;
    }
    @for $i from 0 to $navbar-dropdown-elements {
      div:nth-child(#{$i + 1}) {
        -webkit-animation: load 0s forwards #{$i * 0.08}s;
        animation: load 0s forwards #{$i * 0.08}s;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  #navbar-dropdown-share {
    margin-top: 60px !important;
    gap: 4px !important;
  }
  #navbar-dropdown-export {
    flex-wrap: wrap;
    min-width: 60%;
    gap: 4px !important;
    margin-top: 100px !important;
    margin-left: 260px !important;
  }
}

@media screen and (max-width: 510px) {
  #navbar-dropdown-export {
    min-width: 50%;
    margin-top: 120px !important;
    margin-left: 240px !important;
  }
}
