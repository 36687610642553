.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media screen and (min-width: 1024px) {
    img {
      width: 24%;
    }
  }
  @media screen and (max-width: 1024px) {
    img {
      width: 30%;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: 50%;
    }
  }

  .loader {
    &:after {
      position: absolute;
      margin-left: 0.1rem;
      content: "\00a0\00a0\00a0";
      animation: progress-ellipsis 5s infinite;
    }
  }

  @keyframes progress-ellipsis {
    0% {
      content: "\00a0\00a0\00a0";
    }
    25% {
      content: ".\00a0\00a0";
    }
    50% {
      content: "..\00a0";
    }
    75% {
      content: "...";
    }
  }
}
