//tablet-laptop-desktop
@media screen and (min-width: 700px) {
    #notify-form {
      margin: 2rem;
      display:flex;
      flex-direction:row;
      align-items:stretch;
      gap:0;
      input {
        border: 1px solid $shade2;
        font-size: 100%;
        border-radius: 60px 0 0 60px;
        padding: calc(1.2rem - 1px) 2rem;
        margin-right: -5px;
        outline: none;
        &:focus {
          border: 1px solid $accent;
        }
      }
      button {
        border-radius: 0 60px 60px 0;
        min-width: 8em;
      }
    }
  }
  
  //smartphone
  @media not screen and (min-width: 700px) {
    #notify-form{
      display:flex;
      flex-direction:column;
      align-items:center;
      gap:2vh;
      margin: 2vh;
      input {
        border: 1px solid $shade2;
        font-size: 100%;
        width: 80vw;
        border-radius: 60px 60px 60px 60px;
        padding: 1em 1em;
        line-height: 1.15;
        outline: none;
        &:focus {
          border: 1px solid $accent;
        }
      }
      button {
        border-radius: 60px 60px 60px 60px;
        width: 80vw;
        padding: 1em 1em;
      }
    }
  }