.upload-container{
  width: 100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items:center;


  .file-upload-panel {
    display: flex;
    padding:5rem;
    font-family: "Poppins", sans-serif;

    margin: 0 auto;
    border-radius: 10px;
    @media screen and (min-width: 700px) {
      border-style: dashed;
    }
    @media not screen and (min-width: 700px) {
      //smartphone
      border-style: none;
    }
    @media not screen and (min-height: 700px) {
      //smartphone-landscape
      border-style: none;
    }
    border-color: $shade2;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    @media screen and (min-width: 700px) {
      .panel-label {
        font-size: 1.5rem;
        color: rgba($shade1, 0.5);
      }
      .sm-panel-label {
        font-size: 1.1rem;
        color: rgba($shade1, 0.5);
      }
    }
    @media not screen and (min-width: 700px) {
      //smartphone
      .panel-label {
        display:none;
      }
      .sm-panel-label {
        display:none;
      }
    }
    @media not screen and (min-height: 700px) {
      //smartphone-landscape
      .panel-label {
        display:none;
      }
      .sm-panel-label {
        display:none;
      }
    }

    .service-btn {
      box-shadow: 0 5px 3px -5px $shade1;
    }

  }
}