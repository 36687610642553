.panel {
  width: fit-content;
  height: fit-content;
  text-align: center;
  position: absolute;
  font-family: "Poppins", sans-serif;

  .close-icon {
    height: 0;
    cursor: pointer;
    position: relative;
    &::after {
      position: absolute;
      content: "\00d7";
      margin-top: -22px;
      margin-left: 49%;
    }
  }

  &.nav {
    background-color: $transparent-primary;
    border-radius: 30px;
    padding: 30px;
    left: 180px;
    top: 80px;
    p {
      color: $shade1;
      font-weight: 100;
    }
    &.fadeout {
      -webkit-animation: fadeOut 0.3s;
      animation: fadeOut 0.3s;
      animation-fill-mode: forwards;
      @-webkit-keyframes fadeOut {
        0% {
          opacity: 1;
        }
        99% {
          opacity: 0.01;
        }
        100% {
          opacity: 0;
        }
      }
      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        99% {
          opacity: 0.01;
        }
        100% {
          opacity: 0;
        }
      }
    }

    .url-line {
      display: flex;
      flex-direction: row;
      align-items: baseline;
  
      .url-field-and-note{
        display:flexbox;
        justify-content: center;
      }
      label {
        font-size: 80%;
        color: $shade1;
      }
      input {
        font-size: 50%;
        font-family: monospace;
        color: $shade1;
        border: 2px solid $primary;
        background-color: $shade3;
        padding: 5px;
        margin: 10px;
        width: 300px;
      }
      button {
        margin: 5px;
        background-color: $primary;
        &:hover {
          background-color: $light-primary;
        }
      }
    }
  
    .panel-note {
      margin: 5px;
      font-size: 40%;
    }

    @media screen and (max-width: 767px) {
      .url-line {
        flex-direction: column;
        align-items: center;
        input {
          width: 70vw;
          max-width: 300px
        }
      }
      position: fixed;
      left: 50%;
      transform: translate(-50%, 0);
      height: auto;
      .panel-note {
        font-size: 40%;
      }
    }
  }
  &.info {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $primary;
    background-color: $transparent-primary;
    border-radius: 10px;
    border: 3px solid $primary;
    padding: 16px;
    right: 20px;
    bottom: 20px;

    p {
      color: $primary;
      font-weight: 500;
      font-size: 60%;
    }
    &.fadeout {
      -webkit-animation: fadeOut 1s;
      animation: fadeOut 1s;
      animation-fill-mode: forwards;
      @-webkit-keyframes fadeOut {
        0% {
          opacity: 1;
        }
        99% {
          opacity: 0.01;
        }
        100% {
          opacity: 0;
        }
      }
      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        99% {
          opacity: 0.01;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
  &.alert {
    color: $danger;
    background-color: $transparent-danger;
    border-radius: 10px;
    border: 3px solid $danger;
    padding: 16px;
    right: 20px;
    bottom: 20px;
    p {
      color: $danger;
      font-weight: 500;
      font-size: 60%;
    }
    &.fadeout {
      -webkit-animation: fadeOut 1s;
      animation: fadeOut 1s;
      animation-fill-mode: forwards;
      @-webkit-keyframes fadeOut {
        0% {
          opacity: 1;
        }
        99% {
          opacity: 0.01;
        }
        100% {
          opacity: 0;
        }
      }
      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        99% {
          opacity: 0.01;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }


}
