
@media (hover: hover) {
    // Styles for devices with a mouse
    .controls-legend {
        position: fixed;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);

        font-family: "Poppins", sans-serif;
        font-size:50%;
        color:$shade1;
        display:flex;
        flex-direction: column;

        padding: 5px;
        .control-row{
            display:flex;
            align-items:center;
            img{
                height:50px;
                margin:5px;
            }
            .legend-text{
                width:8ch;
                margin:5px;
                color:$shade1;
            }
        }

        opacity: 1;
        transition: opacity 0.5s ease-in-out;
        &.hide {
            opacity: 0;
            pointer-events:none;
        }
    }
}

@media (hover: none) {
    // Styles for touchscreen devices
    .controls-legend {
        visibility: hidden;
    }
}