#coming-soon-page{
  
  display: flex;
  flex-direction: column;
  align-items:center;
  margin: 10vh
}

#under-construction-image{
  img{
    max-width: 70vw;
    max-height:50vh;
    margin: auto;
  }
}


#back-to-home{
  margin:2rem;
}

.link {
  color: $accent;
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  // &::after {
  //   content: "Home";
  // }
}

.link--arrowed {
  display: inline-block;
  height: 2rem;

  .arrow-icon {
    position: relative;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }

  &:hover {
    .arrow-icon {
      transform: translate3d(5px, 0, 0);
    }
  }
}

