// ---- font-size ---------
body{
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
}

h1 {
  font-size: 250%;
  line-height: 98%;
}

h2 {
  font-size: 180%;
}

h3 {
  font-size: 130%;
}

h4 {
  font-size: 130%;
  font-weight: 300;
  color: $shade1;
}

p {
  font-size: 100%;
  color: $shade1;
}

body {
  font-size: 27px;
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 24px;
  }

  // h1 {
  //   font-size: 250%;
  // }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 22px;
  }

}

@media screen and (max-width: 512px) {
  body {
    font-size: 18px;
  }

}

@media screen and (min-width: 1400px) {
  body {
    font-size: 30px;
  }
}

//-------------
* {
  -webkit-tap-highlight-color: transparent;
}

section {
  width: 100%;
  padding: 0 10%;
}

hr {
  height: 1px;
  background-color: $shade2;
  border: none;
}

.marked {
  color: $primary;
}

//viewer page

button {
  color: white;
  background-color: $accent;
  border: none;
  border-radius: 60px;
  padding: 1.2rem 2rem;
  font-size: 100%;
}

#subtitle h4 {
  margin: 3rem 0 2rem 0;
}

.wrapper {
  overflow: hidden;

  #scene-container {
    /* tell our scene container to take up the full page */
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: -1;

    #canvas-3d{
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  span {
    z-index: 999;
    font-size: 55%;
    bottom: 10px;
    left: 10px;
    position: fixed;
    color: $shade1;
    font-weight: 500;
  }

}
