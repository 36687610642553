#partners {
  display: flex;
  justify-content: space-evenly;
  height: 8rem;
  padding: 1rem 0;
  background-color: $shade3;

  h4 {
    align-self: center;
  }
}


/*---- Features List ----*/
#features-list{
  display:flex;
  flex-direction: column;
  // border-style: dotted;
  row-gap: 5vw;
  padding:1vw 15vw 10vw 15vw;

  .feature{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    // border-style: dashed;
    column-gap: 3rem;

    .feature-image{
      // border-style: dotted;
      flex: 1; // <--means to take all the remaining space
      img{
        display:block; // <--removes a small gap at the bottom, caused by "inline"
        width: 100%; // <--means to fit the dimensions of its parent container
      }
    }
    
    .feature-description{
      // border-style:groove;
      flex: 0 0 40%; // <-- no flexibility. Take the 30% of the parent container
      h3 {
        margin-bottom: 2rem;
      }
      p{
        font-size: 1.8vw;
      }
    }
    
  }
  #feature-export{
    flex-direction: row-reverse;
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  #features-list{
    .feature{
      flex-direction: column;

      .feature-description{
        p{
          font-size: 4vw;
        }
      }
    }
    #feature-export{
      flex-direction: column;
      text-align: center;
    }
  }
}


/*---- Sneak Peek ----*/
#sneak-peek {
  background-color: $shade3;
  display: flex;
  flex-direction: column;
  justify-content: space;
  padding: 0;

  h2 {
    text-align: center;
    margin: 4rem 0;
  }
}

#cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .card {
    flex: 0 0 20%;
    text-align: center;

    p {
      margin-top: 1.6rem;
    }
  }
}

#beta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem;

}


footer {
  display: flex;
  justify-content: space-evenly;
  padding: 4rem;

  .brand {
    margin: 0 0 2rem 0;
  }

  #footer-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: $shade1;

    button {
      &#upload-file-footer {
        margin-bottom: 3rem;
      }
    }
  }
}

#who-we-are {
  width: 50%;
}

.brand {
  display: flex;
  align-items: center;
  margin: 1rem 4%;
}

.logo {
  height: 6rem;
  margin-right: 2rem;
}

#social {
  display: flex;
  flex-direction: column;
  button {
    all: unset;
    cursor: pointer;
  }
  #social-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    .social-button {
      all: unset;
      cursor: pointer;
      margin-left: 2rem;
    }
  }
}

#hero {
  min-height: calc(100vh - 6rem);
  display: flex;
  justify-content: space-evenly;
  align-content: center;

  header {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  #hero-visual {
    margin-left: 1.6rem;
    object-fit: contain;
    width: 40%;
    max-width: 600px;
  }
}

@media screen and (max-width: 1024px) {

  section {
    padding: 0 4%;
  }

  #cards {
    flex-direction: row;

    .card {
      flex: 0 0 50%;
      padding: 0 5%;
      // padding: 0 10%;
      // margin: 2rem 0 0 0;
    }
  }

  #beta {
    flex-direction: column;
  }

  #subscribe {
    margin: 2rem 0 0 0;
  }

  footer {
    padding: 3rem 0;
  }
}

@media screen and (max-width: 768px) {
  body {
    text-align: center;
  }

  .brand {
    margin: 0.5rem 4%;
  }

  .logo {
    margin-right: 1rem;
  }

  #hero {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1.5em;
    margin: 1em 0;
  }

  #subtitle h4 {
    margin: 2rem 0 1rem 0;
  }

  #hero button {
    margin-bottom: 1rem;
  }

  #partners {
    flex-wrap: wrap;
    height: unset;
    gap: 0.5em;

    #TWI {
      max-width: 20%;
    }

    #european {
      max-width: 70%;
    }

    :first-child {
      width: 100%;
    }

    :not(:first-child) {
      justify-content: space-evenly;
    }
  }

  #cards {
    flex-direction: column;
    gap: 1.5em;

    .card {
      flex: 0 0 100%;
    }
  }

  #sneak-peek {
    padding: 20px;

    h2 {
      text-align: center;
      margin: 2rem 0;
    }
  }

  #beta {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 4rem 0;
    gap: 1em;

  }

  footer {
    flex-direction: column;
    padding: 7%;

    .brand {
      justify-content: center;
    }
  }

  #who-we-are {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #footer-buttons {
    margin-top: 3rem;
    flex-direction: row;
    justify-content: space-between;

    button {
      margin: 0;
    }
  }
}

@media screen and (min-width: 1400px) {
  section {
    padding: 0 12%;
  }
}
